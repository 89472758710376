import React, { Component } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import ITeam from '../../common/models/team';

import * as Config from '../config';
import { IsSuperuser } from '../util/Auth';
import Instructions from './Instructions';
import { PROBLEM_VALUES } from '../constants';
import { report } from '../util/Toast';
import XlsxUpload from './XlsxUpload';
import { fetchWithRedirect } from '../util/Api';

const SCORE_INPUT_PLACEHOLDER = '1 if correct, 0 otherwise';
const ANSWER_INPUT_PLACEHOLDER = '';
const ORDINALS = ['first', 'second', 'third', 'fourth'];
const KEYBOARD_SHORTCUTS: { [key: string]: string } = {
  '0': '0',
  ArrowLeft: '0',
  a: '0',

  '1': '1',
  ArrowRight: '1',
  d: '1',

  Backspace: '',
};

interface IScoreEntryProps {}

interface IScoreEntryState {
  inputTeamId: string;
  isTeamSelected: boolean;
  selectedTeamInfo?: ITeam;
  selectedSetNumber: number;
  scores: string[];
  answers: string[];
}

class ScoreEntry extends Component<IScoreEntryProps, IScoreEntryState> {
  selectSetHandlers: (() => void)[] = Array(10);
  changeScoreInputHandlers: ((
    event: React.KeyboardEvent<HTMLInputElement>
  ) => void)[];
  changeAnswerInputHandlers: ((event: any) => void)[];
  clickBlankAnswerHandlers: (() => void)[];

  constructor(props: IScoreEntryProps) {
    super(props);

    this.state = {
      inputTeamId: '',
      isTeamSelected: false,
      selectedSetNumber: 0,
      scores: ['', '', '', ''],
      answers: ['', '', '', ''],
    };

    Array.from(Array(10).keys()).forEach((i) => {
      this.selectSetHandlers[i] = () => this.createSelectSetHandler(i);
    });

    if (Config.PROBLEMS_PER_SET === 4) {
      this.changeScoreInputHandlers = [
        this.createChangeScoreInputHandler(1, 'score2'),
        this.createChangeScoreInputHandler(2, 'score3'),
        this.createChangeScoreInputHandler(3, 'score4'),
        this.createChangeScoreInputHandler(4, 'submit'),
      ];
    } else {
      this.changeScoreInputHandlers = [
        this.createChangeScoreInputHandler(1, 'score2'),
        this.createChangeScoreInputHandler(2, 'score3'),
        this.createChangeScoreInputHandler(3, 'submit'),
      ];
    }

    this.changeAnswerInputHandlers = [
      this.createChangeAnswerInputHandler(1),
      this.createChangeAnswerInputHandler(2),
      this.createChangeAnswerInputHandler(3),
      // ok that this is always here, just will never call it
      this.createChangeAnswerInputHandler(4),
    ];

    const blankEvent = { currentTarget: { value: '' } };
    this.clickBlankAnswerHandlers = [
      () => this.changeAnswerInputHandlers[0](blankEvent),
      () => this.changeAnswerInputHandlers[1](blankEvent),
      () => this.changeAnswerInputHandlers[2](blankEvent),
      () => this.changeAnswerInputHandlers[3](blankEvent),
    ];
  }

  focusElement = (elementId: string) => {
    const element = document.getElementById(elementId);
    element && element.focus && element.focus();
  };

  focusFirstElement = () => {
    if (!this.state.isTeamSelected) {
      this.focusElement('teamId');
    }

    const elementToFocus =
      this.state.selectedSetNumber === 9 ? 'answer1' : 'score1';
    this.focusElement(elementToFocus);
  };

  handleChangeInputTeamId = (event: any) => {
    var inputTeamId: string = event.currentTarget.value;
    inputTeamId = inputTeamId.replace(/[^\d^A-Z]/, '');
    this.setState({ inputTeamId });
  };

  handleClickNextButton = () => {
    var { inputTeamId } = this.state;
    fetchWithRedirect(`/api/admin/team_info?teamId=${inputTeamId}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then(
        report(
          `Loaded info for team ${inputTeamId}`,
          `Could not load info for team ${inputTeamId}`
        )
      )
      .then((result) => result.json())
      .then(({ team }) => {
        if (team) {
          const selectedSetNumber = Math.min(9, team.setsCompleted + 1);
          const setScore =
            team.scores &&
            team.scores.find((score) => score.setNumber === selectedSetNumber);
          let scores = ['', '', '', ''];
          if (setScore) {
            scores = [
              setScore.score1.toString(),
              setScore.score2.toString(),
              setScore.score3.toString(),
              setScore.score4.toString(),
            ];
          }
          this.setState(
            {
              isTeamSelected: true,
              selectedTeamInfo: team,
              selectedSetNumber,
              scores,
            },
            this.focusFirstElement
          );
        }
      });
  };

  handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (this.state.selectedTeamInfo) {
      const payload = {
        teamId: this.state.selectedTeamInfo.teamId,
        setNumber: this.state.selectedSetNumber,
        answer1: this.state.answers[0],
        answer2: this.state.answers[1],
        answer3: this.state.answers[2],
        answer4: this.state.answers[3],
        score1: Number(this.state.scores[0]),
        score2: Number(this.state.scores[1]),
        score3: Number(this.state.scores[2]),
        // Number('') === 0 so this is ok
        score4: Number(this.state.scores[3]),
      };
      fetchWithRedirect('/api/admin/submit_score', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
        credentials: 'include',
      })
        .then(
          report(`Successfully submitted scores`, `Could not submit scores`)
        )
        .then(this.resetForm);
    }
  };

  createSelectSetHandler = (setNumber: number) => {
    const { selectedTeamInfo } = this.state;
    if (selectedTeamInfo) {
      const setScore =
        selectedTeamInfo.scores &&
        selectedTeamInfo.scores.find((score) => score.setNumber === setNumber);
      let scores = ['', '', '', ''];
      if (setScore) {
        scores = [
          setScore.score1.toString(),
          setScore.score2.toString(),
          setScore.score3.toString(),
          setScore.score4.toString(),
        ];
      }
      this.setState(
        {
          selectedSetNumber: setNumber,
          scores,
        },
        this.focusFirstElement
      );
    }
  };

  createChangeScoreInputHandler =
    (scoreNumber: number, focusNext: string) =>
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key !== 'Tab') event.preventDefault();
      const arrayIndex = scoreNumber - 1;

      var key = event.key;
      if (event.keyCode === 229) {
        const newValue = event.currentTarget.value;
        key = newValue.substr(this.state.scores[arrayIndex].length);
      }

      const value = KEYBOARD_SHORTCUTS[key];
      const clearInput = value === '';
      if (value !== undefined) {
        const score =
          value === '0'
            ? '0'
            : PROBLEM_VALUES[this.state.selectedSetNumber].toString();

        this.setState(
          (state) => {
            const scores = state.scores;
            scores[arrayIndex] = clearInput ? '' : score;
            return { scores };
          },
          () => !clearInput && this.focusElement(focusNext)
        );
      } else {
        // force re-render to maintain valid input
        this.setState({});
      }
    };

  createChangeAnswerInputHandler = (answerNumber: number) => (event: any) => {
    const arrayIndex = answerNumber - 1;
    const newValue: string = event.currentTarget.value;
    if (!newValue || newValue.match(/^-?\d*\.?\d*$/)) {
      const gradeValue = !newValue.match(/\d/) ? '0' : newValue;
      this.setState(
        (state) => {
          const { answers, scores } = state;
          answers[arrayIndex] = newValue;
          scores[arrayIndex] = '';
          return { answers, scores };
        },
        () => {
          fetchWithRedirect('/api/admin/get_estimation_score', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              answer: parseFloat(gradeValue),
              problem: arrayIndex,
            }),
          })
            .then((res) => res.json())
            .then((res) => {
              if (res.answer !== parseFloat(gradeValue)) {
                return;
              }
              this.setState((state) => {
                const scores = state.scores;
                scores[arrayIndex] = res.score.toString();
                return { scores };
              });
            });
        }
      );
    } else {
      // force re-render to maintain valid input
      this.setState({});
    }
  };

  resetForm = () => {
    this.setState(
      {
        inputTeamId: '',
        isTeamSelected: false,
        selectedTeamInfo: undefined,
        selectedSetNumber: 0,
        scores: ['', '', '', ''],
        answers: ['', '', '', ''],
      },
      this.focusFirstElement
    );
  };

  renderSetNumberButtonRow = (rowNumber: number) => {
    return Array.from(Array(3).keys()).map((i) => {
      const setNumber = 3 * rowNumber + i + 1;
      const canSubmitSet =
        this.state.selectedTeamInfo &&
        this.state.selectedTeamInfo.setsCompleted + 1 >= setNumber;
      const variant =
        this.state.selectedSetNumber === setNumber
          ? 'primary'
          : canSubmitSet
            ? 'outline-primary'
            : 'outline-secondary';

      return (
        <Col xs={4} md={2} key={setNumber}>
          <Button
            block
            disabled={!this.state.isTeamSelected || !canSubmitSet}
            variant={variant}
            onClick={this.selectSetHandlers[setNumber]}
          >
            Set {setNumber}
          </Button>
        </Col>
      );
    });
  };

  renderSetNumberSelector = () => {
    return (
      <>
        <Row>
          <Col md={6}>
            <p className="mt-1">Set number:</p>
          </Col>
          {this.renderSetNumberButtonRow(0)}
        </Row>
        <Row className="mt-1">
          <Col md={6} className="d-none d-md-block">
            <p className="mt-1">&nbsp;</p>
          </Col>
          {this.renderSetNumberButtonRow(1)}
        </Row>
        <Row className="mt-1">
          <Col md={6} className="d-none d-md-block">
            <p className="mt-1">&nbsp;</p>
          </Col>
          {this.renderSetNumberButtonRow(2)}
        </Row>
      </>
    );
  };

  renderScoreInputs = () => {
    return (
      <>
        {Array.from(Array(Config.PROBLEMS_PER_SET).keys()).map((i) => (
          <Row className="mt-1" key={i}>
            <Col md={6}>
              <p className="mt-1">Score for {ORDINALS[i]} problem in set:</p>
            </Col>
            <Col md={6}>
              <Form.Group controlId={`score${i + 1}`}>
                <Form.Control
                  name={`score${i + 1}`}
                  placeholder={SCORE_INPUT_PLACEHOLDER}
                  value={this.state.scores[i]}
                  disabled={
                    !this.state.isTeamSelected ||
                    (Config.IS_SET_NINE_ESTIMATION &&
                      this.state.selectedSetNumber === 9)
                  }
                  onKeyUp={this.changeScoreInputHandlers[i]}
                />
              </Form.Group>
            </Col>
          </Row>
        ))}
      </>
    );
  };

  renderAnswerInputs = () => {
    if (!Config.IS_SET_NINE_ESTIMATION) {
      return null;
    }

    return (
      <>
        <Row className="mt-3">
          <Col>
            <p>
              The inputs below are only used for set 9. You should type in the
              submitted answer in decimal form as long as it is numeric, and
              otherwise click the <code>Blank</code> button. The corresponding
              score will automatically appear above.
            </p>
          </Col>
        </Row>
        <div className="mt-2" />
        {Array.from(Array(Config.PROBLEMS_PER_SET).keys()).map((i) => (
          <Row className="mt-1" key={i}>
            <Col md={6}>
              <p className="mt-1">Answer for {ORDINALS[i]} problem in set 9:</p>
            </Col>
            <Col xs={7} md={4}>
              <Form.Group controlId={`answer${i + 1}`}>
                <Form.Control
                  name={`answer${i + 1}`}
                  placeholder={ANSWER_INPUT_PLACEHOLDER}
                  value={this.state.answers[i]}
                  disabled={
                    !this.state.isTeamSelected ||
                    this.state.selectedSetNumber !== 9
                  }
                  onChange={this.changeAnswerInputHandlers[i]}
                />
              </Form.Group>
            </Col>
            <Col xs={5} md={2}>
              <Button
                variant="secondary"
                block
                tabIndex={-1}
                disabled={
                  !this.state.isTeamSelected ||
                  this.state.selectedSetNumber !== 9
                }
                onClick={this.clickBlankAnswerHandlers[i]}
              >
                Blank
              </Button>
            </Col>
          </Row>
        ))}
      </>
    );
  };

  render() {
    return (
      <Row>
        <Col lg={7}>
          <Form onSubmit={this.handleFormSubmit}>
            <Row>
              <Col md={4}>
                <Form.Group controlId="teamId">
                  <Form.Control
                    name="teamId"
                    placeholder="First, enter a team ID"
                    value={this.state.inputTeamId}
                    disabled={this.state.isTeamSelected}
                    onChange={this.handleChangeInputTeamId}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Button
                  block
                  disabled={this.state.isTeamSelected}
                  onClick={this.handleClickNextButton}
                >
                  Next
                </Button>
              </Col>
              <Col md={4} className="mt-2 mt-md-0">
                <Button
                  variant="danger"
                  block
                  disabled={!this.state.isTeamSelected}
                  onClick={this.resetForm}
                >
                  Reset
                </Button>
              </Col>
            </Row>
            <hr />
            {this.renderSetNumberSelector()}
            <div className="mt-3" />
            {this.renderScoreInputs()}
            {this.renderAnswerInputs()}
            <Row className="mt-4">
              <Col>
                <Button
                  id="submit"
                  type="submit"
                  block
                  disabled={
                    !this.state.isTeamSelected ||
                    !this.state.scores[0] ||
                    !this.state.scores[1] ||
                    !this.state.scores[2] ||
                    (!this.state.scores[3] && Config.PROBLEMS_PER_SET === 4)
                  }
                >
                  Submit score
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col lg={5} className="mt-4 mt-lg-0">
          <Instructions />
          {IsSuperuser() && (
            <>
              <hr />
              <Button href="/superuser" block>
                Superuser Console
              </Button>
            </>
          )}
          <hr />
          <XlsxUpload />
        </Col>
      </Row>
    );
  }
}

export default ScoreEntry;
