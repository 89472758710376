import React, { Component } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import ITeam from '../../common/models/team';

import { DATA_FETCH_INTERVAL_MS } from '../constants';
import { IsLoggedIn, IsAdmin } from '../util/Auth';
import Leaderboard from '../components/Leaderboard';
import Scoreboard from '../components/Scoreboard';
import Timer from '../components/Timer';

interface IHomePageState {
  teamsSortedByRank: ITeam[];
  teamsSortedById: ITeam[];
  showButtons: boolean;
}

const compareTeamIds = (team1: ITeam, team2: ITeam): number => {
  const val1 = parseInt(team1.teamId);
  const val2 = parseInt(team2.teamId);

  // sort the non-numeric ones, otherwise put them behind numeric ones
  if (isNaN(val1) && isNaN(val2)) {
    return team1.teamId.localeCompare(team2.teamId);
  } else if (isNaN(val1)) {
    return 1;
  } else if (isNaN(val2)) {
    return -1;
  } else {
    return val1 - val2;
  }
};

class HomePage extends Component<{}, IHomePageState> {
  interval?: NodeJS.Timeout;

  constructor(props: {}) {
    super(props);
    this.state = {
      teamsSortedByRank: [],
      teamsSortedById: [],
      showButtons: 'true' !== localStorage.getItem('hideButtons'),
    };
  }

  componentDidMount() {
    this.getScoreboardData();
    this.interval = setInterval(this.getScoreboardData, DATA_FETCH_INTERVAL_MS);
  }

  componentWillUnmount() {
    this.interval && clearInterval(this.interval);
  }

  getScoreboardData = () => {
    const endpoint = '/api/scoreboard_cached';
    fetch(endpoint, {
      method: 'GET',
      cache: 'no-cache',
    })
      .then((data) => data.json())
      .then(({ teams }: { teams: ITeam[] }) => {
        const teamsSortedById = teams.slice(0).sort(compareTeamIds);

        this.setState({
          teamsSortedByRank: teams,
          teamsSortedById,
        });
      });
  };

  renderNavButtons = () => {
    if (IsLoggedIn()) {
      return (
        <>
          {IsAdmin() && <Button href="/admin">Admin Console</Button>}
          <Button className="ml-2" variant="secondary" href="/logout">
            Log Out
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button href="/login">Log In</Button>
          <Button className="ml-2" variant="secondary" href="/register">
            Register
          </Button>
        </>
      );
    }
  };

  render() {
    const { teamsSortedByRank, teamsSortedById, showButtons } = this.state;

    return (
      <Container className="main-container" fluid>
        <Row className="mt-4">
          <Col lg={3}>{showButtons && this.renderNavButtons()}</Col>
          <Col lg={6}>
            <p className="text-center title-text mt-3">Guts Scoreboard</p>
            <div className="mb-0 mb-lg-3">
              <Timer />
            </div>
          </Col>
          <Col lg={3} />
        </Row>
        <hr />
        <Row>
          <Col lg={4} className="leaderboard-container">
            <Leaderboard teams={teamsSortedByRank} />
          </Col>
          <Col lg={8} className="scoreboard-container mt-lg-0 mt-5">
            <Scoreboard teams={teamsSortedById} />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default HomePage;
